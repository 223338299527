import "pdfjs-dist/legacy/web/pdf_viewer.css";
import config from "../../config";
import $ from "jquery/dist/jquery.min"
import {isMobile} from "../../util";
import {appHeaders} from "../../api/api";
export const  pdfjsLib = require("pdfjs-dist/legacy/build/pdf.js")
export const  pdfViewer = require("pdfjs-dist/legacy/web/pdf_viewer.js")
pdfjsLib.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");


// export const  pdfjsLib = require("../../assets/js/pdfjs/dist/legacy/build/pdf.js")
// export const  pdfViewer = require("../../assets/js/pdfjs/dist/legacy/web/pdf_viewer.js")
// // pdfjsLib.GlobalWorkerOptions.workerSrc = require("../../assets/js/pdfjs/generic-legacy/build/pdf.worker.js");
// pdfjsLib.GlobalWorkerOptions.workerSrc = require("../../assets/js/pdfjs/dist/legacy/build/pdf.worker.entry.js");

export const ScaleSpecialValue={
    AUTO: 'auto',
    PAGE_ACTUAL: 'page-actual',
    PAGE_FIT: 'page-fit',
    PAGE_WIDTH: 'page-width',
}
const SelectTypeInPage = {
    FULL:0,
    FOOT:1,
    HEAD:2,
    PART:3
};
/**
 *
 * @param fileUrl
 * @param pdfContainerId
 * @param pdfViewId
 * @param pdfViewer
 * @param eventBus
 * @param pdfLinkService
 * @param pdfFindController
 * @param pdfDoc
 * @param defaultScale
 * @return {PdfInfo}
 * @constructor
 */
function PdfInfo(fileUrl,pdfContainerId,pdfViewId,pdfViewer,eventBus,pdfLinkService,pdfFindController,pdfDoc,defaultScale) {
    this.fileUrl = fileUrl;
    this.pdfContainerId = pdfContainerId;
    this.pdfViewId = pdfViewId;
    this.pdfViewer = pdfViewer;
    this.eventBus = eventBus;
    this.pdfLinkService = pdfLinkService;
    this.pdfFindController = pdfFindController;
    this.pdfDoc = pdfDoc;
    this.pagesContent = [];// 每页的文档内容，最开始时没有初始化
    this.currentScale = defaultScale ? defaultScale : ScaleSpecialValue.PAGE_ACTUAL;
    // this.autoScaleFitViewer = autoScaleFitViewer;
    // this._pageWidth = null;// 每页的宽度，需要等第一页加载完毕后才会初始化。
    // this._initScaled = false;

    /**
     * 获取pdf文档内容
     * @return {Promise<[]>}
     */
    this.getPagesContent = function () {
        let pdfContent = [];
        let canvasNum = this.pdfDoc.numPages;
        let promise = Promise.resolve();
        let allPromise = [];
        for (let i = 1; i <= canvasNum; i++) {
            promise = promise.then(() => {
                return pdfDoc.getPage(i).then(function (page) {
                    return page.getTextContent({normalizeWhitespace: true}).then((textContent) => {
                        let textItems = textContent.items;
                        let strBuf = [];

                        for (let j = 0, jj = textItems.length; j < jj; j++) {
                            strBuf.push(textItems[j].str);
                        }
                        let currentPageContent = strBuf.join('')
                        pdfContent.push(currentPageContent);
                    });
                });
            });
            allPromise.push(promise)
        }
        return Promise.all(allPromise).then(function () {
            return pdfContent
        })
    }
    /**
     * 初始化pagesContent（包含每页内容）属性值。
     * @return {Promise<*[]>}
     */
    this.initPagesContent = function () {

        return this.getPagesContent().then(pagesContent => {
            this.pagesContent = pagesContent
            // this.eventBus.dispatch('pdfPageContentInitialized',pagesContent)
        });
    }
    /**
     * 将全文start坐标和end坐标的对象列表转换为（start所在页数，start相对于该页的字符偏移，end所在页数，end相对于该页的字符偏移，）的对象列表
     * @param {[{start,end}]} needConvertArray 含有start和end的对象的列表，start为闭，end为开
     * @returns {[{start,end,startPage,endPage}]} 转化后（start所在页数，start相对于该页的字符偏移，end所在页数，end相对于该页的字符偏移，）的对象列表,start为闭，end为开
     */
    this.fullTextIndexesConvertToPageIndexInfo = function (needConvertArray) {
        //将对象中的start和end收集成需要转换的位置的set
        let positionInfo = [];
        needConvertArray.forEach(fullTextIndexInfo => {
            positionInfo.push(fullTextIndexInfo.start)
            positionInfo.push(fullTextIndexInfo.end - 1)
        });
        positionInfo = [...new Set(positionInfo)].sort((a, b) => a - b);
        //获取这些坐标所在页，及页内偏移
        const convertMap = this.convertPosition(positionInfo);
        let result = [];
        //拼装返回结果
        needConvertArray.forEach(fullTextIndexInfo => {
            const startConvertedInfo = convertMap.get(fullTextIndexInfo.start)
            const endConvertedInfo = convertMap.get(fullTextIndexInfo.end - 1);
            result.push({
                start: startConvertedInfo.indexInPage,
                end: endConvertedInfo.indexInPage + 1,
                startPage: startConvertedInfo.pageNum,
                endPage: endConvertedInfo.pageNum,
            })
        })
        return result;
    };
    /**
     *
     * @param {Array} needConvertPosition 需要转换的全文坐标信息数组
     * @returns {Map<any, {pageNum,indexInPage}>} 返回需要转换的位置和转换后位置的Map
     */
    this.convertPosition = function (needConvertPosition) {
        let charIndex = 0;// 当前字符index
        let pageNum = 1; // 当前页码
        let needConvertPositionCursor = 0;
        let result = new Map();
        while (pageNum <= this.pagesContent.length && needConvertPositionCursor < needConvertPosition.length) {
            //当前页内容长度
            let currentPdfPageContentLength = this.replaceAllSpace(this.pagesContent[pageNum - 1]).length;
            //下一页起始的字符坐标
            let nextPageStartCharIndex = charIndex + currentPdfPageContentLength;
            while (needConvertPositionCursor < needConvertPosition.length) {
                let position = needConvertPosition[needConvertPositionCursor];
                if (position >= charIndex && position < nextPageStartCharIndex) {
                    result.set(position, {
                        pageNum: pageNum,
                        indexInPage: position - charIndex
                    })
                    needConvertPositionCursor++;
                } else {
                    break;
                }
            }
            charIndex = nextPageStartCharIndex;
            pageNum++;
        }
        return result
    };
    /**
     * 将从startPage的start到endPage的end选区转换为该选区每一页的start和end的数组（前提是该页已经加载出来）。
     * @param {{start,end,startPage,endPage}} rangeInfoInPage 其中start为闭，end为开
     * @returns {null|[{pageNum,eleStart,eleEnd}]} 其中pageNum从0开始
     */
    this.getFixedPageRanges = function (rangeInfoInPage) {
        let pageElement = $(`#${this.pdfViewId} div[data-page-number='${rangeInfoInPage.startPage}']`)
        let textElement = $(pageElement).children(".textLayer")
        if (textElement.children(".endOfContent").length === 0) {
            return null;
        }
        let result = []
        for (let pageIndex = rangeInfoInPage.startPage; pageIndex <= rangeInfoInPage.endPage; pageIndex++) {
            let keywordRange;
            if (pageIndex === rangeInfoInPage.startPage) {
                keywordRange = {
                    start: rangeInfoInPage.start,
                    end: rangeInfoInPage.startPage === rangeInfoInPage.endPage ? rangeInfoInPage.end : null
                };
            } else {
                pageElement = $(`#${this.pdfViewId} div[data-page-number='${ pageIndex}']`)
                textElement = $(pageElement).children(".textLayer")
                if (textElement.children(".endOfContent").length === 0) {
                    continue
                }
                if (pageIndex === rangeInfoInPage.endPage) {
                    keywordRange = {
                        start: 0,
                        end: rangeInfoInPage.end
                    };
                } else {
                    keywordRange = {
                        start: 0,
                        end: null
                    };
                }
            }

            this.fixOffsetFormContentToEleText(textElement.text(), this.replaceAllSpace(this.pagesContent[pageIndex - 1]), keywordRange);
            result.push({
                pageNum: pageIndex,
                eleStart: keywordRange.start,
                eleEnd: keywordRange.end
            });
        }
        return result
    };
    /**
     * 根据在某页的起始字符位置和结束字符位置转换成document中的range对象，并返回当页的document节点。
     * @param {[{pageNum,eleStart,eleEnd}]} pageRanges,其中pageNum从0开始, eleStart为闭，eleEnd为开
     * @param {[]} visiblePageNum
     * @param {boolean} showAppendLayer 是否显示alertLayer，默认为true
     * @param {string} layerClassName layer容器的className。
     * @returns {[null|{appendLayer,documentRange}]} 如果pageRange对应页尚未加载出来，则返回null，否则则返回对应页document对象和对应Range对象。
     */
    this.getRangeAndAppendLayers = function (pageRanges, visiblePageNum, showAppendLayer = true,layerClassName='alertLayer') {
        let result = []
        console.log('getRangeAndAppendLayers showAppendLayer:' + showAppendLayer+',layerClassName:'+layerClassName);
        for (let j = 0; j < pageRanges.length; j++) {
            let selectMeta = pageRanges[j]
            if (visiblePageNum.indexOf(selectMeta.pageNum) < 0) {
                result.push(null)
                continue;
            }
            const page = $(`#${this.pdfViewId} div[data-page-number='${selectMeta.pageNum }']`)
            let textLayer = page.children(".textLayer");
            let allSpan = this.getTextLayerSpans(textLayer)
            let elementRange = this.getElementRangeByIndexRange(allSpan, selectMeta.eleStart, selectMeta.eleEnd)
            let startInfo = elementRange[0]
            let endInfo = elementRange[1]
            // let startInfo = getElementByIndex(allSpan,selectMeta.eleStart,false);
            // let endInfo= getElementByIndex(allSpan,selectMeta.eleEnd,true);
            let markRange = document.createRange();
            markRange.setStart(startInfo.ele.childNodes[0], startInfo.offset);
            markRange.setEnd(endInfo.ele.childNodes[0], endInfo.offset);

            let alertLayer = page.children('.'+layerClassName)
            if (alertLayer.length === 0) {
                alertLayer = $(document.createElement('div'));
                alertLayer.addClass(layerClassName)
                if (layerClassName !== 'alertLayer') {
                    alertLayer.addClass('alertLayer');
                }
                page.append(alertLayer);
            }

            if (showAppendLayer) {
                alertLayer.css('opacity', 1);
            } else {
                alertLayer.css('opacity', 0);
            }
            result.push({
                appendLayer: alertLayer,
                documentRange: markRange
            });
        }
        return result;
    };
    this.showOrHideAppendLayer = function (showOrHide,layerClassName='alertLayer') {
        console.log('showOrHideAppendLayer showOrHide:' + showOrHide);
        let appendLayer = $(`#${this.pdfViewId} .${layerClassName}`);
        if (showOrHide) {
            appendLayer.css('opacity', 1);
        }else{
            appendLayer.css('opacity', 0);
        }
    }
    this.removeAlertLayerHighlights = function (highlightClass,layerClassName='alertLayer') {
        document.querySelectorAll(`#${this.pdfViewId} .${layerClassName} .${highlightClass}`).forEach((ele) => {
            ele.remove();
        })
    };
    this.getElementRangeByIndexRange = function (allSpan, start, end) {
        let offset = 0;
        let startInfo = null, endInfo = null;
        for (let i = 0; i < allSpan.length; i++) {
            let spanTextLength = allSpan[i].textContent.length;
            if (spanTextLength == 0) {
                continue;
            }
            offset += spanTextLength;
            if (offset > start) {
                if (!startInfo || startInfo.ele.offsetTop - allSpan[i].offsetTop > 100) {
                    startInfo = {
                        "ele": allSpan[i],
                        "offset": Math.max(start + spanTextLength - offset, 0),
                        "index": i
                    }
                }
            }
            if (startInfo != null && !end) {
                endInfo = {
                    "ele": allSpan[allSpan.length - 1],
                    "offset": allSpan[allSpan.length - 1].textContent.length,
                    "index": allSpan.length - 1
                }
            } else if (offset >= end) {
                endInfo = {
                    "ele": allSpan[i],
                    "offset": end + spanTextLength - offset,
                    "index": i
                }
            }
            if (startInfo && endInfo) {
                return [startInfo, endInfo]
            }
        }
    };
    this.fixOffsetFormContentToEleText = function (eleText, content, keywordRange) {
        let i = 0, j = 0;
        let start = null, end = null;
        for (; i < eleText.length && j < content.length; i++, j++) {
            let count = 0;
            while (eleText[i + count] !== content[j]) {
                count++;
                if (count === 1000) {
                    return
                }
            }
            i += count;
            if (!start && keywordRange.start === j) {
                start = i;
                if (!keywordRange.end) {
                    break
                }
            }
            if (keywordRange.end - 1 === j) {
                end = i + 1;
                break
            }
        }
        keywordRange.start = start;
        keywordRange.end = end;
    };
    this.getPeerPageRect = function () {
        let page = $(`#${this.pdfViewId} .page[data-page-number]`)[0];
        return {
            width: page.clientWidth,
            height: page.clientHeight,
        }
    };
    this.getRenderFinishPageNum = function (fromOne = false) {
        let result = []
        for (let i = 0; i < this.pagesContent.length; i++) {
            let pageRendered = $(`#${this.pdfViewId} div[data-page-number=${i + 1}] .textLayer .endOfContent`).length > 0;
            if (pageRendered) {
                result.push(fromOne ? i + 1 : i);
            }
        }
        return result;
        // return this.pdfDoc._pages.filter(pageView => {
        //     return pageView.textLayer!==null && pageView.textLayer.renderingDone;
        // }).map(view => {
        //     return fromOne ? view.id : view.id - 1;
        // })
    };
    this.scrollByPage = function (targetPageNum) {
        let targetPage = document.querySelector(`#${this.pdfViewId} [data-page-number='${targetPageNum}']`);
        targetPage.scrollIntoView({block: 'nearest', inline: 'nearest'});
    };
    this.replaceAllSpace = function (key) {
        return key.replace(/[ 　  ]/g, "");
    };
    this.init = function () {
        this.initEventListener();
        return this.initPagesContent();
    };
    this.initEventListener = function () {
        // if (this.autoScaleFitViewer) {
        //     this.initScaleRelated();
        // }
    };
    // /**
    //  * 添加缩放相关事件
    //  */
    // this.initScaleRelated = function () {
    //     this.eventBus.on('pagerendered', () => {
    //         if (this.autoScaleFitViewer) {
    //             this.initScale();
    //         }
    //     });
    //     let resizeTimeout = null;
    //     window.addEventListener('resize',()=>{
    //         if (resizeTimeout) {
    //             clearTimeout(resizeTimeout);
    //         }
    //
    //         resizeTimeout = setTimeout(() => {
    //             this.fitScale();
    //         }, 100);
    //     })
    // }
    // /**
    //  * 初始化scale相关值。
    //  */
    // this.initScale = function () {
    //     if (this._initScaled) {
    //         return;
    //     }
    //     let firstPage = this.pdfViewer.getPageView(0);
    //     if (!firstPage) {
    //         console.log('请在第一页加载完毕之后再进行initScale')
    //         return
    //     }
    //     this._pageWidth = firstPage.div.offsetWidth;
    //     this.fitScale();
    //     this._initScaled = true;
    // }
    // /**
    //  * 让pdf能够正常的显示在container里面。
    //  */
    // this.fitScale = function () {
    //     let viewerWidth = this.pdfViewer.viewer.offsetWidth;
    //     if (!this._pageWidth) {
    //         return
    //     }
    //     let scale = viewerWidth / this._pageWidth;
    //     console.log(`viewerWidth:${viewerWidth},pageWidth:${this._pageWidth},scale:${scale}`);
    //     this.setScale(scale);
    // }

    this.setScale = function (scale) {
        console.log('set scale:',scale)
        // 有时界面宽度可能会发生变化，我们需要绕过pdfView对scale值是否发生变化的检查
        if (scale === this.currentScale) {
            this.pdfViewer._setScale(scale === ScaleSpecialValue.AUTO ? ScaleSpecialValue.PAGE_FIT : ScaleSpecialValue.AUTO);
        }
        this.pdfViewer._setScale(scale);
        this.currentScale = scale;
    }
    // pdf mark 相关*******************************start
    /**
     * 获取选中文本在pdf中的位置信息
     * @param {Range} selectRange
     * @param {string} sText
     * @param {Selection} selectRange
     * @returns {null|{rationEnd: number, rationStart: number, pdfPositionInfo: {startIndex: *, endIndex: number}, extendKeywordInfo: {sentence: string, keyLength: number, offset: number}, selectionInfos: [], selectionText: string}}
     */
    this.getSelectionMarkInfoInPdf = function(selectRange , sText) {
        let startSpan = selectRange.startContainer;
        while (startSpan.nodeName !=="SPAN"){
            startSpan = startSpan.parentElement;
        }
        let textLayerEle = $(selectRange.startContainer).closest(".textLayer")[0];

        let endLayerEle = null;
        let endLayer = $(selectRange.endContainer).closest(".textLayer");
        if (endLayer.length===0) {
            // 如果找不到，说明可能是选区选到了最后的情况，分两种情况
            let currentPage = selectRange.endContainer.closest(".page");
            if (currentPage != null) {
                // 1. 选区到了当前页最后
                endLayerEle = currentPage.querySelector(".textLayer");
            }else{
                //2. 选区到了整个pdf最后
                let textLayers = document.getElementById(this.pdfViewId).querySelectorAll(".page .textLayer");
                endLayerEle = textLayers[textLayers.length - 1];
            }
        }else{
            endLayerEle = endLayer[0];
        }


        let textOffset = this.getStartIndexByElement(startSpan,textLayerEle);
        textOffset+=selectRange.startOffset;
        let startPageNum = Number($(textLayerEle).parent().attr("data-page-number"));
        let endPageNum = Number($(endLayerEle).parent().attr("data-page-number"));
        let sTextLength = sText.length;
        let selectionInfos = []
        for(let i=startPageNum;i<=endPageNum;i++){
            let selectInfo = {
                "pageNum":i-1,
                "eleStart":null,
                "eleEnd":null,
                "type":null,
                "contentStart":null,
                "contentEnd":null
            };
            let eleText = $("div[data-page-number='"+i+"']").text()
            if(startPageNum === endPageNum){//一次就退出了
                selectInfo.type = SelectTypeInPage.PART;
                selectInfo.eleStart=textOffset;
                selectInfo.eleEnd = textOffset + sTextLength
            }else  if(i===startPageNum){//第一页内容的扩展
                selectInfo.type = SelectTypeInPage.FOOT;
                selectInfo.eleStart=textOffset;
                selectInfo.eleEnd = eleText.length;
                let diffLength = selectInfo.eleEnd-selectInfo.eleStart;
                sTextLength-=diffLength
            }else if(i===endPageNum){//最后一页内容的扩展
                selectInfo.type = SelectTypeInPage.HEAD;
                selectInfo.eleStart=0;
                selectInfo.eleEnd = sTextLength
            }else{//中间的都是完整页
                selectInfo.type = SelectTypeInPage.FULL;
                selectInfo.eleStart=0;
                selectInfo.eleEnd = eleText.length;
                sTextLength -= eleText.length
            }
            selectInfo.contentStart = replaceAllSpace(eleText.substring(0,selectInfo.eleStart)).length;
            selectInfo.contentEnd = replaceAllSpace(eleText.substring(0,selectInfo.eleEnd)).length;
            // let pageContent =previewPdfInfo.pdfPageContent[i-1];
            // fixOffsetFormEleTextToContent(eleText,replaceAllSpace(pageContent),selectInfo);
            selectionInfos.push(selectInfo)
        }
        let pdfPositionInfo = this.getTextIndexInAllContentWithoutSpace(selectionInfos[0],selectionInfos[selectionInfos.length-1]);
        let lastPageIndex = startPageNum>1?startPageNum-2:0;
        let nextPageIndex = endPageNum===this.pagesContent.length?endPageNum-1:endPageNum;
        let pageContentCount = 0;
        let pageContentIndex = {};
        for(let k=lastPageIndex;k<=nextPageIndex;k++){
            pageContentIndex[k]=pageContentCount;
            pageContentCount += replaceAllSpace(this.pagesContent[k]).length
        }
        let uniSentenceInfo = this.getSelectTextUniSentence(startPageNum-1,endPageNum-1,selectionInfos[0],selectionInfos[selectionInfos.length-1],pageContentIndex);
        let contentRatio = this.getContentRation(lastPageIndex,nextPageIndex);
        return {
            extendKeywordInfo: uniSentenceInfo,
            rationStart: contentRatio.start,
            rationEnd: contentRatio.end,
            selectionInfos: selectionInfos,
            pdfPositionInfo: pdfPositionInfo
        };
    };
    //获取文档扩展后的比例
    this.getContentRation = function (lastPageNum, nextPageNum) {
        let startIndex, endIndex;
        let contentCount = 0;
        for (let i = 0; i < this.pagesContent.length; i++) {
            if (i === lastPageNum) {
                startIndex = contentCount;
            }
            contentCount += replaceAllSpace(this.pagesContent[i]).length;
            if (i === nextPageNum) {
                endIndex = contentCount;
            }
        }
        return {
            "start": Math.floor(startIndex * 100 / contentCount),
            "end": Math.ceil(endIndex * 100 / contentCount)
        }
    };
    this.getSelectTextUniSentence=function(startPageNum,endPageNum,startInfo,endInfo,pageContentIndex) {
        let lastPageNum = startPageNum>0?startPageNum-1:0;
        let nextPageNum = endPageNum===this.pagesContent.length-1?endPageNum:endPageNum+1;
        let nearbyContent = this.pagesContent.slice(lastPageNum,nextPageNum+1).join("");
        return this.getUniSentenceByKeyword(nearbyContent,startInfo.contentStart+pageContentIndex[startInfo.pageNum],endInfo.contentEnd+pageContentIndex[endInfo.pageNum])
    }
    this.getTextIndexInAllContentWithoutSpace = function (startInfo, endInfo) {
        let pageStartIndex = 0, pageEndIndex = 0;

        for (let i = 0; i < this.pagesContent.length; i++) {
            let contentWithoutSpaceLength = replaceAllSpace(this.pagesContent[i]).length;
            if (i < startInfo.pageNum) {
                pageStartIndex += contentWithoutSpaceLength;
            }
            if (i < endInfo.pageNum) {
                pageEndIndex += contentWithoutSpaceLength;
            } else {
                break;
            }
        }
        return {
            "startIndex": startInfo.contentStart + pageStartIndex,
            "endIndex": endInfo.contentEnd + pageEndIndex
        }
    };
    this.getUniSentenceByKeyword = function (content, start, end) {
        content = replaceAllSpace(content);
        let offset = 0;
        let sentence = content.substring(start, end);
        let sourceTextLength = sentence.length;
        while (content.indexOf(sentence) !== content.lastIndexOf(sentence)) {
            if (start > 0) {
                start -= 1;
                offset += 1
            }
            if (end < content.length) {
                end += 1
            }
            sentence = content.substring(start, end)
        }
        return {"sentence": sentence, "offset": offset, "keyLength": sourceTextLength}
    };
    this.getStartIndexByElement = function (startSpan, textLayer) {
        let allSpan = this.getTextLayerSpans(textLayer)
        let offset = 0;
        for (let i = 0; i < allSpan.length; i++) {
            if (allSpan[i] === startSpan) {
                return offset
            }
            offset += allSpan[i].textContent.length;
        }
    };
    this.getTextLayerSpans = function (textLayer) {
        let allSpan = $(textLayer).children("span");
        if (allSpan.length === 1) {
            // 有时会存在一个SPAN包含多个SPAN的情况
            let grandsonSpan = allSpan.children("span");
            if (grandsonSpan.length > 0) {
                return grandsonSpan;
            }
        }
        return allSpan;
    }
    // pdf mark 相关*******************************end

    // this.init();
    return this;
}
export const getContractVersionPdfUrl = function (versionId, ocrContentPdf) {
    let url = `${config.baseUrl}contract-version/${versionId}/preview-file?random=${Math.ceil(Math.random() * 100)}`
    if (ocrContentPdf) {
        url += '&ocrContentPdf=true';
    }
    return url;
}

export const getContractPdfUrl = function (contractId){
    return `${config.baseUrl}contract/${contractId}/preview-file?random=${Math.ceil(Math.random() * 100)}`;
}

function replaceAllSpace(key) {
    return key.replace(/[ 　  ]/g,"");
}
/**
 *
 * @param fileUrl  下载pdf的url 必需
 * @param pdfContainerId  必需
 * @param pdfViewerId 必需
 * @param eventBus 监听pdf事件的对象 可选
 * @param pdfLinkService 可选
 * @param pdfFindController 可选
 * @param addPdfEventListener 对eventBus对象进行添加事件监听的方法 可选
 * @param autoScaleFitViewer 自动缩放pdf，让page的宽度适应viewer的宽度 可选
 * @param defaultScale 默认缩放值 可选
 * @return {Promise<{PdfInfo}>}
 */
export const initPdfView = function ({fileUrl,pdfContainerId,pdfViewerId,eventBus,pdfLinkService,pdfFindController,addPdfEventListener,autoScaleFitViewer,defaultScale}) {
    return new Promise((resolve, reject) => {
        let container = document.getElementById(pdfContainerId)
        let viewer = document.getElementById(pdfViewerId)
        pdfViewer.DEFAULT_SCALE_VALUE = 'fit-width';


        // 创建eventBus
        if (!eventBus) {
            eventBus =new pdfViewer.EventBus();
        }
        if (addPdfEventListener) {
            addPdfEventListener(eventBus);
        }
        // 创建LinkService
        if (!pdfLinkService) {
            pdfLinkService = new pdfViewer.PDFLinkService({eventBus: eventBus});
        }
        // 创建FindController
        if (!pdfFindController) {
            pdfFindController = new pdfViewer.PDFFindController({
                linkService: pdfLinkService,
                eventBus:eventBus
            });
        }

        // 创建PDFViewer对象
        let pdfViewerObj = new pdfViewer.PDFViewer({
            container: container,
            viewer: viewer,
            eventBus:eventBus,
            linkService: pdfLinkService,
            findController: pdfFindController,
        });

        let headers = isMobile() ? appHeaders : null;
        // linkService设置viewer
        pdfLinkService.setViewer(pdfViewerObj);
        pdfjsLib.getDocument({
            url: fileUrl,
            // cMapUrl: '../../public/pdf-dist/web/cmaps/',
            cMapUrl: 'pdf-dist/web/cmaps/',
            cMapPacked: true,
            disableStream: true,
            disableAutoFetch: true,
            httpHeaders:headers
        }).promise.then((pdfDoc => {
            pdfViewerObj.linkService.setDocument(pdfDoc)
            pdfViewerObj.setDocument(pdfDoc)

            let pageInfo =new PdfInfo(fileUrl, pdfContainerId, pdfViewerId, pdfViewerObj, eventBus, pdfLinkService, pdfFindController, pdfDoc,autoScaleFitViewer)
            pageInfo.init().then(()=>{
                resolve(pageInfo)
                pageInfo.setScale(defaultScale)
            })
        }));
    });
};
